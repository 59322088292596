import type { MenuItem } from "~/types";

export function useHeaderMenu() {
    const store = useMenusStore();
    const menu = computed(() => store.menus.header);
    const selectedGroup = useState(
        "composables.useHeaderMenu.selectedGroup",
        () => null as number | null,
    );
    watchEffect(() => {
        selectedGroup.value = firstIndex(menu.value?.items);
    });
    const selectedSubGroup = useState(
        "composables.useHeaderMenu.selectedSubGroup",
        () => null as number | null,
    );
    watchEffect(() => {
        if (selectedGroup.value === null) {
            selectedSubGroup.value = null;
        } else {
            const item = menu.value?.items[selectedGroup.value!] as any;
            selectedSubGroup.value = firstIndex(item.children);
        }
    });

    const groups = computed<MenuItem[] | null>(() => menu.value?.items ?? null);
    const subGroups = computed<MenuItem[] | null>(
        () => (groups.value as any)?.[selectedGroup.value!]?.children ?? null,
    );
    const items = computed<MenuItem[] | null>(() => {
        return (subGroups.value as any)?.[selectedSubGroup.value!]?.children;
    });

    return {
        selectedGroup,
        selectedSubGroup,
        groupGoods: computed(() => {
            const group = selectedGroup.value;
            if (group === null) return null;
            return groups.value?.[group]?.goods ?? null;
        }),
        groups,
        subGroups,
        items,
    };
}

function firstIndex(arr: any[] | null | undefined): null | number {
    if (!arr) {
        return null;
    }
    if (arr.length === 0) {
        return null;
    }
    return arr.findIndex((i) => !i.is_new && !i.is_sale);
}
