export async function useAllProductsFavorite() {
    const allFavorite = useState("useAllProductsFavorite.count", () => 0);
    const user = useUserStore();
    const email = computed(() => user.data?.email);

    const r = async () => {
        const res = await useFetchWithCredentials<number>(
            "/api/v1/user/favorite/product/count",
            { key: `useAllProductsFavorite.count.${email.value}` },
        );
        allFavorite.value = Number(res.data.value) || 0;
    };

    watch(email, async () => await r());

    if (email) {
        await r();
    }

    return allFavorite;
}
